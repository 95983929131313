<template>
  <MainContent>
    <div class="container">
      <div class="row justify-content-center">
        <div class="col-12">
          <CreateMessageHeader />
        </div>
      </div>
      <div class="row">
        <div class="col-12 col-md-6">
          <CreateMessageForm />
        </div>
        <div class="d-none d-sm-none d-md-block col-12 col-md-6 text-preview text-center">
          <MessagePreview />
        </div>
      </div>
    </div>
  </MainContent>
</template>

<script>
import MainContent from '@/components/MainContent/MainContent'
import CreateMessageHeader from '@/components/Modules/Campaign/CreateCampaign/CreateMessageHeader'
import CreateMessageForm from '@/components/Modules/Campaign/CreateCampaign/CreateMessageForm'
import MessagePreview from '@/components/Modules/Campaign/CreateCampaign/MessagePreview'

export default {
  name: 'CreateMessagePage',
  components: {
    MainContent,
    CreateMessageHeader,
    CreateMessageForm,
    MessagePreview,
  },
}
</script>

<style scoped lang="scss"></style>
